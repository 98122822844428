import { http } from './HttpService'

export function getAllUsers(){
	return http().get('/users');
}

export function getUserById(data){
	return http().get('/user', { 
        params: {
			id: data,
        },
	});
}

export function searchUser(data){
	return http().post('/user/search_user', data);
}

export function deleteUser(data){
	return http().post('/user/delete_user', data);
}

export function updateUserStatus(data){
	return http().post('/user/update_status', data);
}

export function updateUserField(data){
	return http().post('/user/update_field', data);
}
