import { http } from './HttpService'

export function getCompanyToAssign(){
	return http().get('/partner/company_to_assign');
}

export function getPartnerData(){
	return http().get('/partner/partner_data');
}

export function updateMemberPartner(data){
	return http().post('/partner/update_member_partner', data);
}

export function updatePartnerStatus(data){
	return http().post('/partner/update_partner_status', data);
}

export function partnerEmailActivation(data){
	return http().post('/partner/partner_activation', data);
}

