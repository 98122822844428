<template>
    <div class="users">
        <div class="content">
            <TitleComponent />
            <h1><i class="far fa-user"></i> Users Data</h1><br>
            <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                <thead class="kt-bg-fill-info" id="thead-sticky" style="position: sticky; top: 10%; z-index: 10;">
                    <!-- style="color:white;background-color:#003498;" -->
                    <tr style="background-color: #003597;color: #ffff;">
                        <th rowspan="2">ID</th>
                        <th rowspan="2">Username</th>
                        <th rowspan="2">Fullname</th>
                        <th rowspan="2">Company Name</th>
                        <th rowspan="2">Email</th>
                        <th rowspan="2">Phone</th>
                        <th rowspan="2">Active ?</th>
                        <th class="text-center" colspan="2">Last Login</th>
                        <th class="text-center" colspan="2">Database</th>
                        <th rowspan="2">Parent ID</th>
                        <th class="text-center" rowspan="2">Aksi</th>
                    </tr>
                    <tr style="background-color: #003597;color: #ffff;">
                        <th class="text-center">Tanggal</th>
                        <th class="text-center">#</th>
                        <th class="text-center">Name</th>
                        <th class="text-center">Prefix</th>
                    </tr>
                </thead>
                <tbody id="tbody_users">
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import $ from 'jquery'
    import TitleComponent from '@/components/TitleComponent.vue'
    // import axios from "axios"
    // import moment from "moment"
    import swal from 'sweetalert2'
    import * as userService from '../../services/UserService'

    export default {
        name: 'UsersView',
        components: {
            TitleComponent
        },
        beforeMount() {
            getAllUser()

            setTimeout(function(){
                updateStatus()
                // update_field()
            }, 1000);
        }
    }

    function getAllUser(){
        userService.getAllUsers()
        .then(function (response) {
            // handle success

            $("tbody#tbody_users").html('');

            let users_data = response.data,
                tbody = ``;
                
            $.each(users_data, function(i, v){
                let delete_action = (v.database_name !== null) ? `<a href="javascript:;" data-id="${v.id}" data-database-name="${v.database_name}" data-database-prefix="${v.database_prefix}" data-username="${v.username}" class="btn btn-sm btn-clean btn-icon btn-icon-md delete-user" data-toggle="kt-tooltip" data-placement="top" title="Hapus data"><i class="far fa-trash-alt text-info"></i></a>` : '-',
                    
                    no_hp = (v.phone == null) ? '-' : v.phone,
                    login_range = (v.last_login == null) ? 'Tidak pernah login' : `${v.login_range} Hari yg lalu`;

                /*tbody += `
                    <tr class="${(v.company_id != null) ? 'table-primary' : 'table-secondary'}">
                        <td>${v.id}</td>
                        <td>
                            <input type='text' class='update_field' data-table='of_users' data-id='${v.id}' data-company_id='${v.company_id}' data-field='username' style='border:none;' value='${v.username}' />
                        </td>
                        <td>
                            <input type='text' class='update_field' data-table='of_users' data-id='${v.id}' data-company_id='${v.company_id}' data-field='first_name' style='border:none;' value='${v.first_name}' />
                        </td>
                        <td>
                            <input type='text' size='40' class='update_field' data-table='of_users' data-id='${v.id}' data-company_id='${v.company_id}' data-field='company' style='border:none;' value='${v.company}' />
                        </td>
                        <td>
                            <input type='text' size='40' class='update_field' data-table='of_users' data-id='${v.id}' data-company_id='${v.company_id}' data-field='email' style='border:none;' value='${v.email}' />
                        </td>
                        <td align='center'>
                            <a href="https://wa.me/${no_hp}?text=Hallo" target="_blank">wa.me/${no_hp}</a>
                            <hr>
                            <input type='text' class='update_field' data-table='of_users' data-id='${v.id}' data-company_id='${v.company_id}' data-field='phone' style='border:none;' value='${v.phone}' />
                        </td>
                        <td align='center'>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="switch-stats">
                                            <input data-id="${v.id}" type="checkbox" class="fac_status" ${(v.active == 1) ? 'checked' : ''}>
                                            <span class="slider-stats round"></span>
                                            <input type="hidden" name="fac_status">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td align='center'>${(v.last_login == null) ? '-' : v.last_login}</td>
                        <td align='center' style='white-space:nowrap;'>${login_range}</td>
                        <td align='center'>${v.database_name}</td>
                        <td align='center'>${v.database_prefix}</td>
                        <td align='center'>${(v.parent_id == null) ? "parent" : v.parent_id }</td>
                        <td align='center'>
                            <div class="btn btn-group">
                                ${delete_action}
                            </div>
                        </td>
                    </tr>
                `;*/

                tbody += `
                    <tr class="${(v.company_id != null) ? 'table-primary' : 'table-secondary'}">
                        <td>${v.id}</td>
                        <td>${v.username}</td>
                        <td>${v.first_name}</td>
                        <td>${v.company}</td>
                        <td>${v.email}</td>
                        <td align='center'><a href="https://wa.me/${no_hp}?text=Hallo" target="_blank">wa.me/${no_hp}</a></td>
                        <td align='center'>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="switch-stats">
                                            <input data-id="${v.id}" type="checkbox" class="fac_status" ${(v.active == 1) ? 'checked' : ''}>
                                            <span class="slider-stats round"></span>
                                            <input type="hidden" name="fac_status">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td align='center'>${(v.last_login == null) ? '-' : v.last_login}</td>
                        <td align='center' style='white-space:nowrap;'>${login_range}</td>
                        <td align='center'>${v.database_name}</td>
                        <td align='center'>${v.database_prefix}</td>
                        <td align='center'>${(v.parent_id == null) ? "parent" : v.parent_id }</td>
                        <td align='center'>
                            <div class="btn btn-group">
                                ${delete_action}
                            </div>
                        </td>
                    </tr>
                `;

                /*<a href="/user/edit?id=${v.id}&username=${v.username}" target="_blank" class="btn btn-sm btn-clean btn-icon btn-icon-md edit-user" data-toggle="kt-tooltip" data-placement="top" title="Edit category">
                                    <i class="far fa-edit text-info"></i>
                                </a>*/
            });

            $("tbody#tbody_users").html(tbody);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    function updateStatus(){
        $('.fac_status').on('change', function() {
            let id = $(this).data('id'),
                status = (this.checked) ? '1' : '2';

            let data = {
                id: id,
                status: status
            };

            userService.updateUserStatus(data)
            .then(function () {
                swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: 'Status Berhasil diubah!'
                });
            });
        });
    }

    /*function update_field(){
        $('.update_field').on('change', function() {
            let table = $(this).data('table'),
                field = $(this).data('field'),
                id = $(this).data('id'),
                value = this.value;


            let data = {
                table: table,
                field: field,
                id: id,
                value: value
            };

            userService.updateUserField(data)
            .then(function () {
                swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    html: `<b>${field}</b> Berhasil diubah menjadi <b>${value}</b>!`
                });
            });
        });
    }*/

    $(document).on('click', 'a.delete-user', function() {
        let id = $(this).data('id'),
            db_name = $(this).data('database-name'),
            db_prefix = $(this).data('database-prefix'),
            username = $(this).data('username');

        userService.getUserById(id)
        .then(function (response) {
            if(response.data[0].active === 1){
                swal.fire({
                    icon: 'warning',
                    title: 'Gagal!',
                    text: `Data ${username} (${db_prefix}) Masih aktif, tidak dapat dihapus.`
                });
            } else {

                swal.fire({
                    title: `${username}`,
                    text: 'Anda akan melakukan pengecekan data untuk proses DELETE User dan Table!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Lanjutkan!'
                }).then((result) => {
                    if (result.value === true)
                    {
                        let data = {
                            id: id,
                            db_name: db_name,
                            db_prefix: db_prefix
                        }

                        userService.searchUser(data)
                        .then(function (response) {
                            let data_user,query_drop;
                            if(response.data[1]){
                                data_user = (response.data[1][0].statement == null) ? "Data user" : `Data user dan table prefix ${db_prefix.split('keu')[0]}`;
                                query_drop = (response.data[1][0].statement) ? response.data[1][0].statement : null;
                            } else {
                                data_user = "Data user";
                                query_drop = null;
                            }

                            setTimeout(function(){

                                swal.fire({
                                    title: `${data_user} akan dihapus Permanent! apakah anda yakin? ${(query_drop == null) ? "" : "(cek kembali query dibawah)" }`,
                                    text: `${(query_drop == null) ? "" : query_drop }`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Ya, Saya Yakin!'
                                }).then((result) => {
                                    if (result.value === true)
                                    {
                                        let data = {
                                            id: id,
                                            db_name: db_name,
                                            query_drop: query_drop
                                        }

                                        userService.deleteUser(data)
                                        .then(function () {
                                            swal.fire({
                                                icon: 'success',
                                                title: 'Berhasil!',
                                                text: 'data Berhasil Dihapus.'
                                            });

                                            getAllUser();
                                            setTimeout(function(){
                                                updateStatus()
                                            }, 1000);
                                        });
                                    }
                                });

                            }, 1000);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    }
                });
            }
        });

    });
</script>