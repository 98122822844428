<template>
    <div class="home">
        <div class="content">
            <TitleComponent />
            <!-- <router-link to="/testing">JUST FOR TESTING</router-link> -->
            <h1><i class="far fa-building"></i> Company Baru kurang dari 3 hari</h1><br>
            <div class="table-responsive">
                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                    <thead>
                        <tr style="background-color: #003597;color: #ffff;">
                            <th rowspan="2">ID</th>
                            <th rowspan="2">Company</th>
                            <th rowspan="2">Tgl dibuat</th>
                            <th rowspan="2">No. HP</th>
                            <th class="text-center" colspan="2">Database</th>
                            <!-- <th class="text-center" rowspan="2">Aksi</th> -->
                        </tr>
                        <tr style="background-color: #003597;color: #ffff;">
                            <th class="text-center">Name</th>
                            <th class="text-center">Prefix</th>
                        </tr>
                    </thead>
                    <tbody id="tbody_new_company">
                        <tr v-for="user in new_users" :key="user.id">
                            <td>{{ user.id }}</td>
                            <td>{{ user.company }}</td>
                            <td>{{ user.created_date }}</td>
                            <td>
                                <a :href="'https://wa.me/'+ user.phone +'?text=Hallo'" target="_blank">wa.me/{{ user.phone }}</a>
                            </td>
                            <td align='center'>{{ user.database_name }}</td>
                            <td align='center'>{{ user.database_prefix }}</td>
                            <!-- <td align='center'>
                                <span class="d-none">{{ user.username }}</span>
                                <div class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="kt-tooltip" data-placement="top" title="Lihat detail data">
                                    <router-link :to="{ name: 'CompanyDetail', params: { id: user.id, db_prefix: user.database_prefix, company: user.company }}"><i class="far fa-file-lines text-info"></i></router-link>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import $ from 'jquery'
    import TitleComponent from '@/components/TitleComponent.vue'
    import * as companyService from './../services/CompanyService'
    // import axios from "axios"

    // let current_url = window.location.protocol + '//' + window.location.host;

    export default {
        name: 'HomeView',
        components: {
            TitleComponent
        },
        mounted(){
            //API Call
            companyService.getCompaniesCustom()
            .then((res)=> {
                this.users = res.data;
            });

            companyService.getNewCompanies()
            .then((res)=> {
                this.new_users = res.data;

                if(res.data.length === 0){
                    let tbody = `
                        <tr>
                            <td class='text-center' colspan='7'>Tidak ada data register baru</td>
                    `;
                    $("tbody#tbody_new_company").html(tbody);
                }

            });

            localStorage.setItem('alert', 'show');
        },
        data: function() {
            return {
                users:[],
                new_users:[]
            }
        }
    }

    $("span#username").text("iman");

</script>
