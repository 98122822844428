<template>
    <Sidebar />
    <Alert v-if="$store.state.isLoggedIn" />
    <div class="container">
        <router-view />
    </div>
    <Footer />
</template>

<script>
    // @ is an alias to /src
    import Sidebar from '@/components/SidebarComponent.vue'
    import Footer from '@/components/FooterComponent.vue'
    import Alert from '@/components/AlertComponent.vue'
    import $ from 'jquery'

    export default {
        components: {
            Sidebar,
            Footer,
            Alert
        },
        beforeCreate: function() {
            this.$store.dispatch('authenticate');
        }
    }

    $(".sidebar").click();
    let dayInMilliseconds = 1000 * 60 * 60 * 4;

    setInterval(function(){
        localStorage.removeItem('token');
        localStorage.removeItem('alert');
    }, dayInMilliseconds);

</script>

<style lang="scss">
    @import "assets/sass/plugins";
    @import "assets/sass/style";
</style>