<template>
    <div class="users">
        <div class="content">
            <TitleComponent />

            <div id="trigger-partner-data" style="cursor: pointer;">
                <h1><i class="fas fa-user-tie"></i> Partner Data <i class="fas fa-arrows-alt-v text-info"></i></h1><br>
            </div>
            <div id="partner-data">
                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                    <thead class="kt-bg-fill-info" id="thead-sticky" style="position: sticky; top: 10%; z-index: 10;">
                        <!-- style="color:white;background-color:#003498;" -->
                        <tr style="background-color: #003597;color: #ffff;">
                            <th>Username</th>
                            <th>Email</th>
                            <th>No HP</th>
                            <th>Status</th>
                            <th>Tgl Daftar</th>
                            <th>Referral code</th>
                            <th class="text-center">Alamat</th>
                            <th class="text-center">#</th>
                        </tr>
                    </thead>
                    <tbody id="tbody1">
                    </tbody>
                </table>
            </div>
            
            <br>
            <hr>
            <br>

            <div id="trigger-partner-assign" style="cursor: pointer;">
                <h1><i class="fa fa-users"></i> Assign member to Partner Data ? <i class="fas fa-arrows-alt-v text-info"></i></h1><br>
            </div>
            <div id="partner-assign">
                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                    <thead class="kt-bg-fill-info" id="thead-sticky" style="position: sticky; top: 10%; z-index: 10;">
                        <!-- style="color:white;background-color:#003498;" -->
                        <tr style="background-color: #003597;color: #ffff;">
                            <th>Company</th>
                            <th>Dibawah naungan?</th>
                            <th class="text-center">#</th>
                        </tr>
                    </thead>
                    <tbody id="tbody2">
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import $ from 'jquery'
    import TitleComponent from '@/components/TitleComponent.vue'
    // import axios from "axios"
    import moment from "moment"
    import swal from 'sweetalert2'
    import * as partnerService from '../../services/PartnerService'

    // let current_url = window.location.protocol + '//' + window.location.hostname + ":3000";
    // let current_url = "https://api-saas-management.neraca.id";

    export default {
        name: 'UsersView',
        components: {
            TitleComponent
        },
        beforeMount() {
            getAllCompanyAssign()
            getAllPartnerData()

            setTimeout(function(){
                updateStatus()
                $("div#partner-data").slideUp();
                $("div#trigger-partner-data").click(function(){
                    $("div#partner-data").slideToggle();
                });

                $("div#partner-assign").slideUp();
                $("div#trigger-partner-assign").click(function(){
                    $("div#partner-assign").slideToggle();
                });
            }, 1000);
        }
    }

    function getAllPartnerData(){
        partnerService.getPartnerData()
        .then(function (response) {

            $("tbody#tbody1").html('');
            let data = response.data,
                tbody1 = ``;
            $.each(data, function(i, v){
                let new_activation = `<a href="javascript:;" class="h5 partner-activation" data-partner-email="${v.email}" data-first-name="${v.first_name}" data-partner-id="${v.id}"><span style="padding:8%;" class="badge badge-primary"><i class="fas fa-envelope"></i> Kirim email Aktivasi?</span></a>`,
                    active = `<span style="padding:8%;" class="badge badge-success">Akun Aktif</span>`;

                tbody1 += `
                    <tr>
                        <td>${v.username}</td>
                        <td>${v.email}</td>
                        <td>${v.phone_number}</td>
                        <td align='center'>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="switch-stats">
                                            <input data-id="${v.id}" type="checkbox" class="fac_status" ${(v.active == 1) ? 'checked' : ''}>
                                            <span class="slider-stats round"></span>
                                            <input type="hidden" name="fac_status">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>${moment(v.created_date).format('DD-MM-YYYY HH:mm:ss')}</td>
                        <td>${v.referral_code}</td>
                        <td width="20%">
                            (${v.city_name}) ${v.address}
                        </td>
                        <td align="center" width="20%">
                            ${(v.active == 1) ? active : new_activation }
                        </td>
                    </tr>
                `;
            });

            $("tbody#tbody1").html(tbody1);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });

        setTimeout(function(){
            updateStatus();
        }, 1000);
    }

    function getAllCompanyAssign(){
        partnerService.getCompanyToAssign()
        .then(function (response) {

            $("tbody#tbody2").html('');
            let data = response.data,
                tbody2 = ``;
                
            $.each(data, function(i, v){
                let assign = `<span style="padding:8%;" class="badge badge-primary"><i class="fas fa-user-tag"></i> Assign</span>`,
                    reassign = `<span style="padding:8%;" class="badge badge-info"><i class="fas fa-user-cog"></i> Re-assign</span>`;

                tbody2 += `
                    <tr>
                        <td>${v.company_name}</td>
                        <td>${v.partner_username}</td>
                        <td align="center">
                            <a href="javascript:;" class="h4 assign-partner" data-company-id="${v.id}" data-company-name="${v.company_name}" data-partner-id="${v.partner_id}" data-partner-name="${v.partner_username}">${(v.partner_id == null) ? assign : reassign }</a>
                        </td>
                    </tr>
                `;
            });

            $("tbody#tbody2").html(tbody2);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    $(document).on('click', 'a.partner-activation', function() {
        let data = {
            id: $(this).data('partnerId'),
            email: $(this).data('partnerEmail'),
            first_name: $(this).data('firstName'),
        };

        swal.fire({
            icon: 'info',
            html: `Kirim aktivasi ke ${data.email} ?`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Kirim',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Batal',
            width: 600,
        }).then((result) => {
            if (result.isConfirmed) {
                partnerService.partnerEmailActivation(data)
                .then(function (response) {
                    console.log(response.statusText);
                    swal.fire(
                        'Terkirim!',
                        `Berhasil kirim Aktivasi ke ${data.email}`,
                        'success'
                    )
                });
            }
        })
    })

    $(document).on('click', 'a.assign-partner', function() {
        let data = $(this).data();
        
        partnerService.getPartnerData()
        .then(function (response) {

            $("tbody#tbody2").html('');
            let partner_data = response.data,
                option = `<option value="" selected>- Pilih Partner -</option>`;

            $.each(partner_data, function(i,v){
                option += `
                    <option value="${v.id}">${v.username}</option>
                `;
            });

            let html = `
                <div class="row">
                    <div class="col-sm-12 h4">
                        Perusahaan <b>${data.companyName}</b> Sekarang dibawah Naungan <b>${data.partnerName}</b><br><br><br>
                    </div>
                    <div class="col-sm-4 h5 float-right">
                        <b>Assign ke :</b>
                    </div>
                    <div class="col-sm-7">
                        <select class="form-control" name="partner_assign">
                            ${option}
                        </select>
                    </div>
                </div>
                <br><br><br>
            `;

            swal.fire({
                html: html,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Assign',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Batal',
                width: 600,
            }).then((result) => {
                if (result.isConfirmed) {
                    let partner_text = $("select[name='partner_assign'] option:selected").text(),
                        partner_id = $("select[name='partner_assign']").val(),
                        company_id = data.companyId;

                    setTimeout(function(){
                        let data = {
                            partner_id: partner_id,
                            company_id: company_id,
                            partner_text: partner_text,
                        };

                        assign_partner(data)
                    }, 1000);


                }
            })

        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });

        setTimeout(function(){
            getAllCompanyAssign();
        }, 1000);
    });

    function assign_partner(data){
        partnerService.updateMemberPartner(data)
        .then(function (res) {
            swal.fire(
                'Assigned!',
                `Perusahaan Berhasil di-Assign ke ${data.partner_text}`,
                'success'
            )
            console.log(res.statusText)
            getAllCompanyAssign()
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    function updateStatus(){
        $('.fac_status').on('change', function() {
            let id = $(this).data('id'),
                status = (this.checked) ? '1' : '2';

            let data = {
                id: id,
                status: status
            };

            partnerService.updatePartnerStatus(data)
            .then(function () {
                swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: 'Status Berhasil diubah!'
                });

                getAllPartnerData();
            });
        });
    }
</script>