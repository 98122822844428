<template>
	<div class="sidebar">
		<div align="center">
			<img style="max-height: 120px; padding: 5%;" src="../assets/img/icon_neraca.png"><span id="logo-text">Neraca.ID</span>
		</div>
		<router-link v-if="$store.state.isLoggedIn" to="/"><div v-on:click="setActive('home')" :class="{ active: isActive('home') }"><i class="fas fa-house"></i> Home</div></router-link>
		<router-link v-if="$store.state.isLoggedIn" to="/companies"><div v-on:click="setActive('companies')" :class="{ active: isActive('companies') }"><i class="fas fa-cogs"></i> Manage Business Owner</div></router-link>
		<!-- <router-link v-if="$store.state.isLoggedIn" to="/users"><div v-on:click="setActive('users')" :class="{ active: isActive('users') }"><i class="fas fa-cogs"></i> Manage Users</div></router-link> -->
		<router-link v-if="$store.state.isLoggedIn" to="/partners"><div v-on:click="setActive('partners')" :class="{ active: isActive('partners') }"><i class="fas fa-cogs"></i> Manage Partners</div></router-link>
		<div v-if="$store.state.isLoggedIn"><center><i class="fas fa-minus"></i></center></div>
		<div v-if="$store.state.isLoggedIn">
			<span><i class="fas fa-circle-user"></i> {{ (this.$store.state.username) ? this.$store.state.username : 'User' }}</span><br>
		</div>

		<router-link v-if="!$store.state.isLoggedIn" to="/login"><div class="active">Login</div></router-link>
		<div v-if="$store.state.isLoggedIn" v-on:click.prevent="logout()" id="user" style="bottom: 10px;">
			<i class="fas fa-door-open"></i> LOGOUT
		</div>

	</div>
</template>

<script>
	// import $ from 'jquery'
	import * as auth from '../services/AuthService';
	
	var url = window.location.href.split("/");

	export default {
		data() {
			let data = {
				activeItem: (url.at(-1) == '') ? 'home' : `${url.at(-1)}`
			}

			return data
		},
		methods: {
			isActive: function (menuItem) {
				return this.activeItem === menuItem
			},
			setActive: function (menuItem) {
				this.activeItem = menuItem
			},
			logout: function() {
				auth.logout();
				this.$router.push("/login");
			}
		}
	}
</script>