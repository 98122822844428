<template>
    <div class="authenticate">
        <div class="content">
            <TitleComponent />
            <div class="row">
                <div class="col-4">
                    
                </div>
                <div class="col-4">
                    <form class="form-custom" v-on:submit.prevent="onSubmit">
                        <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                            <input v-model="username" type="text" class="form-control" id="username">
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input v-model="password" type="password" class="form-control" id="password">
                        </div>
                        <div class="mb-3">
                            <button type="submit" class="btn btn-primary">Login</button>
                        </div>
                    </form>  
                </div>
                <div class="col-4">
                    
                </div>    
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TitleComponent from '@/components/TitleComponent.vue'
    import * as auth from '../../services/AuthService'

    export default {
        name: 'loginAuth',
        components: {
            TitleComponent
        },
        data: function() {
            return {
                username: '',
                password: ''
            }
        },
        methods: {
            onSubmit: async function(){
                const user = {
                    username: this.username,
                    password: this.password
                }

                await auth.login(user);
                this.$router.push("/");
            }
        }
    }
</script>