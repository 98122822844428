import axios from 'axios'
import { http } from './HttpService'

export function getAllCompanies(){
	return http().get('/companies');
}

export function getCompaniesCustom(){
	return http().get('/companies_custom');
}

export function getNewCompanies(){
	return http().get('/new_companies');
}

export function getCompanyDetail(data){
	return http().get('/company_detail', { 
        params: data
	});
}

export function dynamicCompany(){
	return http().get('/dynamic_company');
}

export function removeFolder(username, id){
	let rmlink = `https://demo.neraca.id/api/remove_folder/${username}/${id}/true`;
	// let rmlink = `http://localhost/akuntansi_v2.2/api/remove_folder/${username}/${id}/true`;

	axios.post(rmlink)
	.then(function (response) {
		console.log(response.data);
	})
	.catch(function (error) {
		console.log(error.response.data);
	});
}
