<template>
    <div class="users">
        <div class="content">
            <TitleComponent />
            <div id="content-details"></div>

            <!-- Modal PART -->
            <div class="modal fade" id="detailsCompanies" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"><b>Info Perusahaan : <span class="company"></span></b></h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <b>Terakhir Login :</b><p id="last_login"></p>
                                        </div>
                                        <div class="col-sm-6">
                                            <b>Saldo ULB :</b><p id="saldo_ulb"></p>
                                        </div>
                                        <div class="col-sm-6">
                                            <b>Jumlah User Dibawahnya :</b><p id="child"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <br>
                            <hr>
                            <br>

                            <h5><b>Details <span class="company"></span></b></h5><br>
                            <div class="table-responsive">
                                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                                    <thead>
                                        <tr style="background-color: #003597;color: #ffff;">
                                            <th>Fitur</th>
                                            <th>Terakhir Input</th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tbody_detail_company"></tbody>
                                </table>
                            </div>

                            <br>
                            <hr>
                            <br>

                            <h5><b>Settings <span class="company"></span></b></h5><br>
                            <div class="table-responsive">
                                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                                    <thead>
                                        <tr style="background-color: #003597;color: #ffff;">
                                            <th>Nama</th>
                                            <th>Alamat</th>
                                            <th>npwp</th>
                                            <th>Type</th>
                                            <th>Cabang ?</th>
                                            <th>Pkp ?</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tbody_settings_company"></tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import $ from 'jquery'
    import TitleComponent from '@/components/TitleComponent.vue'
    // import axios from "axios"
    import moment from "moment"
    import swal from 'sweetalert2'
    import * as userService from '../../services/UserService'
    import * as companyService from '../../services/CompanyService'

    export default {
        name: 'UsersView',
        components: {
            TitleComponent
        },
        beforeMount() {
            loadContent()
        }
    }

    function updateStatus(){
        $('.fac_status').on('change', function() {
            let id = $(this).data('id'),
                status = (this.checked) ? '1' : '2';

            let data = {
                id: id,
                status: status
            };

            userService.updateUserStatus(data)
            .then(function () {
                swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: 'Status Berhasil diubah!'
                });
            });
        });
    }

    function getUserById(data_params){
        $("tbody#tbody_detail_company").html('');
        $("tbody#tbody_settings_company").html('');

        const data = {
            user_id: data_params.id,
            db_prefix: data_params.dbPrefix
        }

        $("p#last_login").text('');
        $("p#saldo_ulb").text('');
        $("p#child").text('');
        $("p#last_login").text(data_params.lastLogin);
        $("p#saldo_ulb").text(data_params.saldoUlb);
        $("p#child").text(data_params.child);

        companyService.getCompanyDetail(data)
        .then(function (response) {
            let data = response.data,
                tbody_detail = ``,
                tbody_settings = ``;
            
            if(data[2].child != "TABLE MASIH VERSI LAMA"){
                $.each(data[2].child, function(i,v){
                    let date_range = (v.date_range == '-') ? '-' : `${v.date_range} hari yg lalu`;
                    tbody_detail += `
                        <tr>
                            <td>${v.modul}</td>
                            <td>${(isValidDate(v.last_data)) ? moment(v.last_data).format('DD-MM-YYYY HH:mm:ss') : v.last_data}</td>
                            <td>${date_range}</td>
                        </tr>
                    `;
                });
            } else {
                tbody_detail += `
                    <tr>
                        <td colspan='3' class='text-center'>TABLE MASIH VERSI LAMA</td>
                    </tr>
                `;
            }

            if(data[1].settings != "TABLE MASIH VERSI LAMA"){
                $.each(data[1].settings, function(i,v){
                    tbody_settings += `
                        <tr>
                            <td>${v.nama}</td>
                            <td>${v.alamat}</td>
                            <td>${v.npwp}</td>
                            <td>${v.type}</td>
                            <td>${v.cabang}</td>
                            <td>${v.is_pkp}</td>
                        </tr>
                    `;
                });
            } else {
                tbody_settings += `
                    <tr>
                        <td colspan='6' class='text-center'>TABLE MASIH VERSI LAMA</td>
                    </tr>
                `;
            }

            $("tbody#tbody_detail_company").html(tbody_detail);
            $("tbody#tbody_settings_company").html(tbody_settings);
        });
    }

    function loadContent(){
        companyService.dynamicCompany()
        .then(function (response) {

            let data = response.data,
                html = ``;

            $.each(data, function(i_first,v_first){
                let tbody = ``;
                html += `
                    <div id="trigger-${v_first[0].database_name}" style="cursor: pointer;">
                        <h1><i class="fas fa-database"></i> ${v_first[0].database_name} <i class="fas fa-arrows-alt-v text-info"></h1></i><br>
                    </div>
                    <div id="${v_first[0].database_name}">
                        <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                            <thead class="kt-bg-fill-info" id="thead-sticky" style="position: sticky; top: 10%; z-index: 10;">
                                <tr style="background-color: #003597;color: #ffff;">
                                    <th rowspan="2">User ID</th>
                                    <th rowspan="2">Company ID</th>
                                    <th rowspan="2">Username</th>
                                    <th rowspan="2">Company Name</th>
                                    <th rowspan="2">Email</th>
                                    <th rowspan="2">Phone</th>
                                    <th rowspan="2">Active ?</th>
                                    <th class="text-center" colspan="2">Database</th>
                                    <th class="text-center" rowspan="2">Aksi</th>
                                </tr>
                                <tr style="background-color: #003597;color: #ffff;">
                                    <th class="text-center">Name</th>
                                    <th class="text-center">Prefix</th>
                                </tr>
                            </thead>
                            <tbody id="tbody_${v_first[0].database_name}">
                            </tbody>
                        </table>
                    </div>
                    <br>
                    <hr>
                    <br>
                `;

                $.each(v_first, function(i,v){
                    let delete_action = (v.database_name !== null) ? `<a href="javascript:;" data-id="${v.id}" data-company-id="${v.company_id}" data-database-name="${v.database_name}" data-database-prefix="${v.database_prefix}" data-username="${v.username}" class="btn btn-sm btn-clean btn-icon btn-icon-md delete-user" data-toggle="kt-tooltip" data-placement="top" title="Hapus data"><i class="far fa-trash-alt text-info"></i></a>` : '-',
                        
                        no_hp = (v.phone == null) ? '-' : v.phone,
                        login_range = (v.last_login == null) ? 'Tidak pernah login' : `${v.login_range} Hari yg lalu`,
                        last_login = (v.last_login == null) ? '-' : v.last_login;

                    tbody += `
                        <tr>
                            <td>${v.id}</td>
                            <td>${v.company_id}</td>
                            <td>${v.username}</td>
                            <td>${v.company}</td>
                            <td>${v.email}</td>
                            <td align='center'><a href="https://wa.me/${no_hp}?text=Hallo" target="_blank">wa.me/${no_hp}</a></td>
                            <td align='center'>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="switch-stats">
                                                <input data-id="${v.id}" type="checkbox" class="fac_status" ${(v.active == 1) ? 'checked' : ''}>
                                                <span class="slider-stats round"></span>
                                                <input type="hidden" name="fac_status">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td align='center'>${v.database_name}</td>
                            <td align='center'>${v.database_prefix}</td>
                            <td align='center'>
                                <div class="btn btn-group">
                                    <a href="javascript:;" data-id="${v.id}" data-db-name="${v.database_name}" data-db-prefix="${v.database_prefix}" data-username="${v.username}" data-company-name="${v.company}" data-last-login="${last_login } (${login_range})" data-saldo-ulb="${v.saldo_ulb}" data-child="${v.child}" class="btn btn-sm btn-clean btn-icon btn-icon-md company-details" data-bs-toggle="modal" data-bs-target="#detailsCompanies" data-toggle="kt-tooltip" data-placement="top" title="Details Perusahaan"><i class="far fa-file-lines text-info"></i></a>

                                    ${delete_action}

                                </div>
                            </td>
                        </tr>
                    `;
                });

                setTimeout(function(){
                    slideContent(`trigger-${v_first[0].database_name}`, `${v_first[0].database_name}`);
                    $(`tbody#tbody_${v_first[0].database_name}`).html(tbody);
                    updateStatus();
                }, 1000);
            });

            $("div#content-details").html(html);
        });
    }

    function slideContent(trigger_el, el){
        $(`div#${el}`).slideUp();
        $(`div#${trigger_el}`).click(function(){
            $(`div#${el}`).slideToggle();
        });
    }

    // function untuk cek apakah value itu Date atau bukan
    function isValidDate(value) {
        var dateWrapper = new Date(value);
        return !isNaN(dateWrapper.getDate());
    }

    $(document).on('click', 'a.company-details', function() {
        let data = $(this).data();

        getUserById(data);

        $("span.company").text(data.companyName);
    });

    $(document).on('click', 'a.delete-user', function() {
        let id = $(this).data('id'),
            company_id = $(this).data('company-id'),
            db_name = $(this).data('database-name'),
            db_prefix = $(this).data('database-prefix'),
            username = $(this).data('username');

        userService.getUserById(id)
        .then(function (response) {
            if(response.data[0].active === 1){
                swal.fire({
                    icon: 'warning',
                    title: 'Gagal!',
                    text: `Data ${username} (${db_prefix}) Masih aktif, tidak dapat dihapus.`
                });
            } else {

                swal.fire({
                    title: `${username}`,
                    text: 'Anda akan melakukan pengecekan data untuk proses DELETE User dan Table!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Lanjutkan!'
                }).then((result) => {
                    if (result.value === true)
                    {
                        let data = {
                            id: id,
                            company_id: company_id,
                            db_name: db_name,
                            db_prefix: db_prefix
                        }

                        userService.searchUser(data)
                        .then(function (response) {
                            let data_user,query_drop;
                            if(response.data[1]){
                                data_user = (response.data[1][0].statement == null) ? "Data user" : `Data user dan table prefix ${db_prefix.split('keu')[0]}`;
                                query_drop = (response.data[1][0].statement) ? response.data[1][0].statement : null;
                            } else {
                                data_user = "Data user";
                                query_drop = null;
                            }

                            setTimeout(function(){

                                swal.fire({
                                    title: `${data_user} akan dihapus Permanent! apakah anda yakin? ${(query_drop == null) ? "" : "(cek kembali query dibawah)" }`,
                                    text: `${(query_drop == null) ? "" : query_drop }`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Ya, Saya Yakin!'
                                }).then((result) => {
                                    if (result.value === true)
                                    {
                                        let data = {
                                            id: id,
                                            company_id: company_id,
                                            db_name: db_name,
                                            query_drop: query_drop
                                        }

                                        userService.deleteUser(data)
                                        .then(function () {
                                            swal.fire({
                                                icon: 'success',
                                                title: 'Berhasil!',
                                                text: 'data Berhasil Dihapus.'
                                            });


                                            loadContent();
                                            setTimeout(function(){
                                                companyService.removeFolder(username,company_id);
                                                updateStatus()
                                            }, 1000);
                                        });
                                    }
                                });

                            }, 1000);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    }
                });
            }
        });
    });
</script>