<template v-if="$store.state.isLoggedIn">
    <div style="float:right;min-width:40%;">
        <div id="alert_new_registration" class="content alert alert-success" style="position:fixed;z-index:100;max-height:200px;margin:1%;padding:2%;" role="alert">
            <div class="row">
                <div class="col-10">
                    <h4 class="alert-heading"><b>Aktivitas Registrasi Baru</b></h4>
                </div>
                <div class="col-2">
                    <div id="close_alert" align='right' style="font-size:20px;margin-bottom:5%;cursor:pointer;color:red;">x</div>
                </div>
            </div>
            <hr>
            <p>Wah, ada <b>{{ count_register }}</b> user baru <b>Register</b> kurang dari 3 Hari yg lalu, Mohon untuk segera di-Follow up.</p>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    jgn munculkan lagi untuk login sekarang ?
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    // import axios from 'axios';
    import * as companyService from '../services/CompanyService'

    // let current_url = window.location.protocol + '//' + window.location.host;

    export default {
        mounted(){
            //API Call
            companyService.getCompaniesCustom()
            .then((res)=> {
                this.users = res.data;
            });

            companyService.getNewCompanies()
            .then((res)=> {
                if(res.data.length === 0){
                    $('div#alert_new_registration').hide();
                } else {
                    this.count_register = res.data.length;
                    $('div#alert_new_registration').show();
                }

            });

            $("div#close_alert").click(function(){
                companyService.getNewCompanies()
                .then((res)=> {
                    $('div#alert_new_registration').fadeOut(1000);
                    setTimeout(function(){
                        if(localStorage.getItem('alert') != null){
                            if(res.data.length === 0){
                                $('div#alert_new_registration').fadeOut(500);
                            } else {
                                this.count_register = res.data.length;
                                $('div#alert_new_registration').fadeIn(500);
                            }
                        }
                    }, 10000);

                });
            });

            $("#flexCheckDefault").change(function() {
                if($("#flexCheckDefault").prop('checked') == true){
                    localStorage.removeItem('alert');
                } else {
                    localStorage.setItem('alert', 'show');
                }
            });
        },
        data: function() {
            return {
                count_register:[]
            }
        }
    }
</script>