import { createStore } from 'vuex'
import * as auth from '../services/AuthService'

// let current_url = window.location.protocol+"//"+window.location.hostname+":8080";
// let current_url = window.location.protocol+"//"+window.location.hostname;

export default createStore({
    state: {
        isLoggedIn: false,
        apiUrl: `https://api-saas-management.neraca.id/api`,
        // apiUrl: `${current_url}/api`,
        // apiUrl: `http://localhost:3000/api`,
        username: null,
        userId: null
    },
    getters: {
    },
    mutations: {
        authenticate(state){
            state.isLoggedIn = auth.isLoggedIn();
            if(state.isLoggedIn){
                state.username = auth.getUsername();
                state.userId = auth.getUserId();
            } else {
                state.username = null;
                state.userId = null;
            }
        }
    },
    actions: {
        authenticate(context){
            context.commit('authenticate');
        }
    },
    modules: {
    }
})
