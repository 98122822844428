import { createRouter, createMemoryHistory } from 'vue-router'
import * as auth from '../services/AuthService'

/*authentication part*/
import Login from '../views/authentication/LoginAuthentication.vue'

/*Manage Company / Home Part*/
import HomeView from '../views/HomeView.vue'
import CompanyDetail from '../views/company/CompaniesDetail.vue'

/*Manage User Part*/
import Users from '../views/users/UsersView.vue'
import Testing from '../views/users/TestingView.vue'


/*=========== v2 =============*/ 
/*Manage Partner Part*/
import Companies from '../views/company/CompaniesView.vue'

/*Manage Partner Part*/
import Partners from '../views/partners/PartnersView.vue'
/*========== end v2 ============*/

const routes = [
    /*authentication part*/
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if(!auth.isLoggedIn()){
                next();
            } else {
                next('/');
            }
        }
    },
    /*Manage Company / Home*/
    {
        path: '/',
        name: 'home',
        component: HomeView,
        beforeEnter: (to, from, next) => {
            if(auth.isLoggedIn()){
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/company_detail',
        name: 'CompanyDetail',
        component: CompanyDetail,
        props: true,
        beforeEnter: (to, from, next) => {
            if(auth.isLoggedIn()){
                next();
            } else {
                next('/login');
            }
        }
    },
    /*Manage User Part*/
    {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: (to, from, next) => {
            if(auth.isLoggedIn()){
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/testing',
        name: 'JustTesting',
        component: Testing,
        beforeEnter: (to, from, next) => {
            if(auth.isLoggedIn()){
                next();
            } else {
                next('/login');
            }
        }
    },
    /*Manage company*/
    {
        path: '/companies',
        name: 'companies',
        component: Companies,
        beforeEnter: (to, from, next) => {
            if(auth.isLoggedIn()){
                next();
            } else {
                next('/login');
            }
        }
    },
    /*Manage Partner*/
    {
        path: '/partners',
        name: 'partners',
        component: Partners,
        beforeEnter: (to, from, next) => {
            if(auth.isLoggedIn()){
                next();
            } else {
                next('/login');
            }
        }
    }
]

const router = createRouter({
    history: createMemoryHistory(),
    routes
})

export default router
