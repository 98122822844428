<template>
    <div class="users">
        <div class="content">
            <TitleComponent />
            <h1><i class="far fa-user"></i> Detail Company <span class="company"></span></h1><br>
            <div class="table-responsive">
                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                    <thead>
                        <tr style="background-color: #003597;color: #ffff;">
                            <th>Fitur</th>
                            <th>Terakhir Input</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody id="tbody_detail_company"></tbody>
                </table>
            </div>

            <br>
            <hr>
            <br>

            <h1><i class="far fa-user"></i> Settings Company <span class="company"></span></h1><br>
            <div class="table-responsive">
                <table class="table table-hover table-rounded table-bordered border gy-7 gs-7">
                    <thead>
                        <tr style="background-color: #003597;color: #ffff;">
                            <th>Nama</th>
                            <th>Alamat</th>
                            <th>npwp</th>
                            <th>Type</th>
                            <th>Cabang ?</th>
                            <th>Pkp ?</th>
                        </tr>
                    </thead>
                    <tbody id="tbody_settings_company"></tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import $ from 'jquery'
    import TitleComponent from '@/components/TitleComponent.vue'
    // import axios from "axios"
    import moment from "moment"
    import { useRoute } from 'vue-router'
    import * as companyService from '../../services/CompanyService'
    
    // let current_url = window.location.protocol + '//' + window.location.host;

    export default {
        setup() {
            const route = useRoute(),
                data = route.params;
            getUserById(data);
        },
        components: {
            TitleComponent
        }
    }

    function getUserById(data_params){
        const data = {
            user_id: data_params.id,
            db_prefix: data_params.db_prefix
        }

        companyService.getCompanyDetail(data)
        .then(function (response) {
            $("tbody#tbody_detail_user").html('');
            let data = response.data,
                tbody_detail = ``,
                tbody_settings = ``;
            
            if(data[2].child){
                $.each(data[2].child, function(i,v){
                    let date_range = (v.date_range == '-') ? '-' : `${v.date_range} hari yg lalu`;
                    tbody_detail += `
                        <tr>
                            <td>${v.modul}</td>
                            <td>${(isValidDate(v.last_data)) ? moment(v.last_data).format('DD-MM-YYYY HH:mm:ss') : v.last_data}</td>
                            <td>${date_range}</td>
                        </tr>
                    `;
                });
            } else {
                tbody_detail += `
                    <tr>
                        <td colspan='3' class='text-center'>TABLE MASIH VERSI LAMA</td>
                    </tr>
                `;
            }

            if(data[1].settings){
                $.each(data[1].settings, function(i,v){
                    tbody_settings += `
                        <tr>
                            <td>${v.nama}</td>
                            <td>${v.alamat}</td>
                            <td>${v.npwp}</td>
                            <td>${v.type}</td>
                            <td>${v.cabang}</td>
                            <td>${v.is_pkp}</td>
                        </tr>
                    `;
                });
            } else {
                tbody_settings += `
                    <tr>
                        <td colspan='6' class='text-center'>ERROR QUERY</td>
                    </tr>
                `;
            }


            $("tbody#tbody_detail_company").html(tbody_detail);
            $("tbody#tbody_settings_company").html(tbody_settings);
            $("span.company").text(data_params.company);
        });
    }

    // function untuk cek apakah value itu Date atau bukan
    function isValidDate(value) {
        var dateWrapper = new Date(value);
        return !isNaN(dateWrapper.getDate());
    }

</script>