import store from '../store';
import { http } from './HttpService';
import swal from 'sweetalert2';
import jwt_decode from "jwt-decode";

export function isLoggedIn(){
	const token = localStorage.getItem('token');

	return token != null;
}

export function login(user){
	return http().post('/auth', user).then(res => {
		if(res){
			if(res.data.message){
				swal.fire({
                    icon: 'warning',
                    title: 'Gagal!',
                    text: res.data.message
                });
			} else {
				setToken(res.data.token);
			}
		}
	});
}

export function logout(){
	localStorage.clear();
	store.dispatch('authenticate');
}

export function getToken(){
	return localStorage.getItem('token');
}

function setToken(token){
	localStorage.setItem('token', token);
	store.dispatch('authenticate');
}

export function getUsername(){
	const token = decodeToken();
	if(!token){
		return null;
	}

	return token.user.username;
}

export function getUserId(){
	/*const token = decodeToken();
	if(!token){
		return null;
	}*/

	return 1;
}

function decodeToken(){
	const token = getToken();
	if(!token) {
		return null;
	}

	return jwt_decode(token);
}